import React from "react";
import { Link } from "gatsby";

const Providers = ({ image, name, path }) => {
  return (
    <Link to={path}>
      <div className="bg-white flex flex-col justify-center items-center rounded-md shadow-md group hover:bg-sunstate-primary cursor-pointer mb-4">
        <div className="py-8 px-8 flex flex-col justify-center items-center">
          <img
            className="rounded-full border-8 border-gray-100 shadow-lg mb-4"
            alt={name}
            src={image}
          />
          <p className="text-xl text-gray-800 group-hover:text-white">{name}</p>
          <p className="text-lg font-thin text-gray-800 group-hover:text-white">
            Board Certified Internal Medicine
          </p>
        </div>
      </div>
    </Link>
  );
};

export default Providers;
