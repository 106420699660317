import React from "react";
import Provider from "./Provider";

import farideh from "../img/farideh.jpg";
import zia from "../img/zia.jpg";
import pothen from "../img/pothen.jpg";

const Providers = () => {
  return (
    <div className="w-full bg-white flex justify-center items-center border-b">
      <div className="w-2/3 flex flex-col justify-center items-center py-16">
        <p className="text-4xl font-thin text-gray-800 mb-8 mt-4 text-center">
          Choose Your Provider
        </p>
        <div className="w-full flex flex-row flex-wrap justify-around items-center">
          <Provider
            image={farideh}
            name="Farideh Zadeh, MD"
            path="/provider/farideh-zadeh-md"
          />
          <Provider
            image={zia}
            name="Zia Fatemi, MD"
            path="/provider/zia-fatemi-md"
          />
          <Provider
            image={pothen}
            name="Pothen Koruth, MD"
            path="/provider/pothen-koruth-md"
          />
        </div>
      </div>
    </div>
  );
};

export default Providers;
